// Third party imports
import React, { useState } from "react";
import cx from "classnames";
import Slider from "react-slick";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";

// Components
import ExlyModal from "common/Components/ExlyModal";
import {
  CarouselNextArrow,
  CarouselPrevArrow,
} from "features/Common/modules/CarouselHelperComponents/CarouselHelperComponents";

// Style, utils and others
import style from "./GuideCarousel.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const GuideCarousel = ({
  slides = [],
  open,
  onClose,
  classes = {},
  desktopModalWidth,
  modalTitle = "Features",
  ...restProps
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isDesktop = useDesktopMediaQuery();

  const onCloseGuide = () => {
    setCurrentSlide(0);
    onClose();
  };

  const settings = {
    slidesToShow: 1,
    prevArrow: <CarouselPrevArrow />,
    nextArrow: <CarouselNextArrow onClose={onClose} />,
    infinite: false,
    dots: true,
    slidesToScroll: 1,
    dotsClass: cx("slick-dots slick-thumb", style.carouselDots, classes.dots),
    customPaging: function (props) {
      return (
        <div
          className={cx(
            style.customDot,
            props == currentSlide ? style.activeDot : style.nonActiveDot
          )}
        />
      );
    },
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
  };
  return (
    <ExlyModal
      open={open}
      padded={false}
      title={isDesktop ? modalTitle : null}
      customHeader={isDesktop ? null : <></>}
      customFooter={<></>}
      onClose={onClose}
      modal_props={{ modalPaperClassName: style.desktopModalPaper }}
      mobile_modal_props={{
        paperClassName: style.modalPaper,
        escapeKeyDownClose: true,
      }}
      className={style.modalPaper}
      desktopModalWidth={desktopModalWidth}
      {...restProps}
    >
      {!isDesktop && (
        <div className={style.featureHeading}>
          <div className={style.positonDiv} />
          <span>{modalTitle}</span>
          <IconButton className="p-0" onClick={onCloseGuide}>
            <CloseRoundedIcon className={style.closeIcon} />
          </IconButton>
        </div>
      )}
      <Slider
        {...settings}
        className={style.carousel}
        trackStyle={{ display: "flex", alignItems: "center" }}
      >
        {slides.map(({ title, icon, description }) => (
          <div className={style.slide} key={title}>
            <img src={icon} alt={title} className={style.icon} />
            <div className={style.title}>{title}</div>
            <div className={style.description}>{description}</div>
          </div>
        ))}
      </Slider>
    </ExlyModal>
  );
};

export default GuideCarousel;
